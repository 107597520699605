/* FAQAccordion.css */
.faq-answer {
  overflow: hidden;
  transition: max-height 0.3s ease-in;
}

.faq-closed {
  max-height: 0;
}

.faq-open {
  max-height: 800px; /* Adjust as needed for your content */
}
